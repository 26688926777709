



















































































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'

import { ConfigurationCardData, NodeInfo } from '@/types/state'
import ConfigurationCard from '@/components/ConfigurationCard.vue'

import { Buffer } from 'buffer'


/* types */
type VueForm = Vue & { validate(): boolean, reset(): void, resetValidation(): void }
type StageFormObj = {
    name: string;
    backButton: string;
    requestedProceedFuncParam?: string[]
    proceedFunction?: (() => void) | ((...n: any[]) => void)
    rollbackFunction?: () => void;
}


/* enums */
enum SnackbarStatus {
    FAIL,
    SUCCESS
}


/* helper functions */
const requiredField  = function(text?: string) { return (input: string): boolean | string => (input !== null && input !== "") || (text ?? "Verplicht") }
const onlyNumbers    = function(text?: string) { return (input: string): boolean | string => /^\d+$/.test(input) || (text ?? "Vul alleen cijfers in") }
const requiredSelect = function(text?: string) { return (input: any): boolean | string    => (input && input != { }) || (text ?? "Verplicht") }
const maxCharacters  = function(max: number, text?: string) {
    return (input: string): boolean | string => (input == "" || (input && input.length <= max)) || (text ?? `Maximaal ${max} karakter${max != 1 ? "s" : ""}`)
}


/* class */
@Component({
    components: {
        ConfigurationCard
    }
})
export default class AddEMRA extends Vue {
    /* constants */
    EMRA_NAME_RULES      = [ requiredField(), maxCharacters(32),
        (name: string): boolean | string => (!this.emraNames?.includes(name)) || "Naam is al in gebruik" ]
    EMRA_SERIAL_ID_RULES = [ maxCharacters(16) ]
    EMRA_CONFIG_RULES    = [ requiredSelect() ]

    OLD_SIM_SELECT_RULES  = [ ] //requiredSelect("Kies een bestaande SIM of voeg een nieuwe SIM toe") ]

    SIM_NUMBER_RULES               = [ requiredField(), maxCharacters(16), onlyNumbers() ]
    SIM_CARD_NUMBER_RULES          = [ requiredField(), maxCharacters(32), onlyNumbers() ]
    SIM_PUK_CODE_RULES             = [ requiredField(), onlyNumbers(),
        (sim: string): boolean | string => (sim && sim.length == 8) || "Vul 8 cijfers in" ]
    SIM_USERNAME_PASSWORD_RULES    = [ requiredField(), maxCharacters(30) ]
    SIM_PRIVATE_IP_RULES           = [ requiredField(),
        (ip: string): boolean | string => /^(\b25[0-5]|\b2[0-4][0-9]|\b[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/.test(ip) || "Vul een geldig IP-adres in: ###.###.###.###" ]
    SIM_NETWORK_SELECT_RULES       = [ requiredField() ]
    SIM_CARRIER_BOARD_SELECT_RULES = [ requiredField() ]

    SYSTEM_TYPE_ID_RULES = [ requiredField(), onlyNumbers() ]

    BOARD_NAME_RULES          = [ requiredField(), maxCharacters(64) ]
    BOARD_MANUFACTURER_RULES  = [ requiredField(), maxCharacters(32) ]
    BOARD_VERSION_RULES       = [ requiredField(), maxCharacters(16) ]
    BOARD_BIT_RULES           = [ requiredField(), onlyNumbers("Vul alleen hele getallen tussen 0 en 2048 in"),
        (bit: number): boolean | string => (bit >= 0 && bit <= 2048) || "Vul een getal tussen 0 en 2048 in" ]
    BOARD_DOCUMENTATION_RULES = [ maxCharacters(128) ]

    STORAGE_NAME_RULES         = [ requiredField(), maxCharacters(64) ]
    STORAGE_TYPE_RULES         = [ requiredField(), maxCharacters(64) ]
    STORAGE_MANUFACTURER_RULES = [ requiredField(), maxCharacters(64) ]
    STORAGE_SIZE_RULES         = [ requiredField(), onlyNumbers("Vul alleen hele getallen tussen 0 en 2048 in"),
        (size: number): boolean | string => (size >= 0 && size <= 2048) || "Vul een getal tussen 0 en 2048 in" ]
    
    RELEASE_VERSION_RULES      = [ requiredField(), maxCharacters(64) ]
    RELEASE_SUPPORTED_OS_RULES = [ requiredField() ]
    RELEASE_RELEASED_AT_RULES  = [ requiredField(), maxCharacters(32) ]
    
    COUNTRY_CODE_RULES = [ requiredField(), maxCharacters(2),
        (code: string): boolean | string => /^[A-Z][A-Z]$/.test(code) || "Vul twee hoofdletters in" ]
    COUNTRY_NAME_RULES = [ requiredField(), maxCharacters(32) ]

    ADD_EMRA_HEADERS         = [ "Board", "Storage", "Release", "Country" ]
    ADD_EMRA_HEADERS_DISPLAY = [ "Board", "Opslag", "Release", "Land" ]

    SNACKBAR_SUCCESS_COLOR = "#9fc735"
    SNACKBAR_FAIL_COLOR    = "#d62328"

    SET_CONFIG_YOURSELF_CARD_DATA = {
        title: "Anders",
        headers: [],
        items: ["Stel zelf de waarden in"]
    }


    /* variables */
    /// user values ///
    chosenEmraName = ""
    chosenEmraSerialId = ""

    placementDate = ""
    placementDateMenu = false

    boardSelect   = ""
    releaseSelect = ""
    storageSelect = ""
    countrySelect = ""
    simSelect     = ""

    newSimNumber             = ""
    newSimCardNumber         = ""
    newSimPukCode            = ""
    newSimUsername           = ""
    newSimPassword           = ""
    newSimPrivateIP          = ""
    newSimNetworkSelect      = ""
    newSimCarrierBoardSelect = ""

    newSystemTypeId    = 0
    newSystemTypeImage = null
    newSystemTypeImageValue: any = null

    newBoardName          = ""
    newBoardManufacturer  = ""
    newBoardVersion       = ""
    newBoardBit           = ""
    newBoardDocumentation = ""

    newStorageName         = ""
    newStorageType         = ""
    newStorageManufacturer = ""
    newStorageSize         = ""

    newReleaseVersion           = ""
    newReleaseSupportedOSSelect = ""
    newReleaseReleasedAt        = ""

    newCountryCode = ""
    newCountryName = ""

    /// programmatically assigned ///
    formValid = false
    
    newBoardFormValid = false
    newStorageFormValid = false
    newReleaseFormValid = false
    newCountryFormValid = false
    newSimFormValid = false
    newSystemTypeFormValid = false

    formKey = 0

    selectedEMRAConfigPreset = 0
    selectedSystemType = 0
    addedObjId = ""

    showAddConfigsForm = false; addConfigsCurrentForm = 0
    showSimForm        = false
    showSystemTypeForm = false

    snackbar      = false
    snackbarText  = ""
    snackbarColor = ""

    emraChipsDict: { [key: string]: any } = {
        configChip: "",
        simChip: "",
        systemTypeChip: ""
    }


    /* getters and setters */
    get currentForm(): VueForm { return this.$refs[this.extraFormIsShown || "emra-form"] as VueForm }

    get currentFormValid(): boolean {
        switch (this.extraFormIsShown) {
            case "new-board-form":   return this.newBoardFormValid
            case "new-storage-form": return this.newStorageFormValid
            case "new-release-form": return this.newReleaseFormValid
            case "new-country-form": return this.newCountryFormValid
            case "new-sim-form":     return this.newSimFormValid
            case "new-system-type-form":    return this.newSystemTypeFormValid

            default:                 return this.formValid
        }
    }


    get loading(): boolean { return !this.$store.getters['addEmra/IsPreloaded'] }
    get preloadData(): any { return this.$store.getters['addEmra/PreloadData'] }

    get allNodes(): Array<NodeInfo> { return this.$store.getters["default/NodeInfos"] }
    get userId(): string   { return this.$store.getters['default/UserId'] }


    get presetEMRAConfigurations(): ConfigurationCardData[] {
        const configurations = this.preloadData.presetEmraConfigurations

        let unpackedConfigs: ConfigurationCardData[] = []
        if (configurations) {
            for (let c = 0; c < configurations.length; c++) {
                const config = configurations[c];
                unpackedConfigs.push({
                    title: config.name,
                    headers: this.ADD_EMRA_HEADERS,
                    items: [...this.ADD_EMRA_HEADERS.map(h => config[h.toLowerCase()])]
                })
            }
        }

        unpackedConfigs.push(this.SET_CONFIG_YOURSELF_CARD_DATA)

        return unpackedConfigs
    }

    get emraNames(): string[]      { return this.preloadData.emraNames }
    get boards(): any[]            { return this.preloadData.boards }
    get storages(): any[]          { return this.preloadData.storages }
    get releases(): any[]          { return this.preloadData.releases }
    get operatingSystems(): any [] { return this.preloadData.operatingSystems }
    get countries(): any[]         { return this.preloadData.countries }
    get allSims(): any[]           { return this.preloadData.allSims }
    get sims(): any[]              { return this.preloadData.sims }
    get virtualPrivateNetworks(): any[] { return this.preloadData.virtualPrivateNetworks }
    get carrierBoards(): any[]     { return this.preloadData.carrierBoards }
    get systemTypeImages(): any[]  { return this.preloadData.systemTypeImages?.map((x: {systemType: number, image: string, display: any}) => ({ systemType: x.systemType, image: x.image ? Buffer.from(x.image, "base64") : null })) }
    get nodeVariables(): any[]     { return this.preloadData.nodeVariables }

    get boardDisplays(): string[]           { return this.boards?.map((x: any) => x.display) }
    get storageDisplays(): string[]         { return this.storages?.map((x: any) => x.display) }
    get releaseDisplays(): string[]         { return this.releases?.map((x: any) => x.display) }
    get operatingSystemDisplays(): string[] { return this.operatingSystems?.map((x: any) => x.display) }
    get countryDisplays(): string[]         { return this.countries?.map((x: any) => x.display) }
    get simDisplays(): string[]             { return this.sims?.map((x: any) => x.display) }
    get systemTypeNodeVariableId(): number  { return this.objFromId("Systeemtype", this.nodeVariables, "name")?.id }

    get extraFormIsShown(): string {
        if (this.showAddConfigsForm) {
            switch (this.addConfigsCurrentForm) {
                case 1: return "new-board-form"
                case 2: return "new-storage-form"
                case 3: return "new-release-form"
                case 4: return "new-country-form"
            }
            return "addConfigsForm"
        }
        if (this.showSimForm) return "new-sim-form"
        if (this.showSystemTypeForm) return "new-system-type-form"
        return ""
    }

    get inputAlreadyExists(): boolean {
        if (this.showAddConfigsForm) {
            switch(this.addConfigsCurrentForm) {
                case 1: return this.boards.findIndex(b => b.name == this.newBoardName && b.manufacturer == this.newBoardManufacturer && b.version == this.newBoardVersion && b.bit == this.newBoardBit) >= 0
                case 2: return this.storages.findIndex(s => s.name == this.newStorageName && s.type == this.newStorageType && s.manufacturer == this.newStorageManufacturer && s.size == this.newStorageSize) >= 0
                case 3: return this.releases.findIndex(r => r.version == this.newReleaseVersion && r.supportedOperatingSystemId == this.newReleaseSupportedOSSelect) >= 0
                case 4: return this.countries.findIndex(c => c.code == this.newCountryCode || c.name == this.newCountryName) >= 0
            }
        }
        if (this.showSimForm) {
            return this.allSims.findIndex(s => s.number == this.newSimNumber || s.cardNumber == this.newSimCardNumber || (s.privateIPAddress == this.newSimPrivateIP && s.networkId == this.newSimNetworkSelect)) >= 0
        }
        if (this.showSystemTypeForm) {
            return this.systemTypeImages.findIndex(s => s.systemType == this.newSystemTypeId) >= 0
        }
        return false
    }

    get shouldShowEMRAConfigSelects(): boolean {
        return this.selectedEMRAConfigPreset == this.presetEMRAConfigurations.length - 1
    }

    get placementDateText(): string {
        const formattedDate = this.formatDate(this.placementDate)
        return formattedDate == "//" ? "" : formattedDate
    }

    get allPlacementDates(): string[] {
        return this.allNodes?.filter(n => n.placementDate).map(n => new Date(new Date(n.placementDate).getTime() + Math.abs(new Date(n.placementDate).getTimezoneOffset()*60*1000)).toISOString().substring(0, 10))
    }

    
    /* methods */
    @Watch("newSystemTypeImage")
    updateSystemTypeImagePreview(): void {
        if (this.newSystemTypeImage) {
            let fr = new FileReader()

            fr.onload = () => {
                this.newSystemTypeImageValue = fr.result
            }
            fr.readAsDataURL(this.newSystemTypeImage as unknown as Blob)
        } else {
            this.newSystemTypeImageValue = null
        }
    }

    @Watch("selectedEMRAConfigPreset")
    updateEMRAConfigSelections(): void {
        this.setEMRAConfigSelections()
    }

    setEMRAConfigSelections(): void {
        const selected = this.presetEMRAConfigurations[this.selectedEMRAConfigPreset]
        if (selected.headers.length === 0) {
            this.boardSelect = this.storageSelect = this.releaseSelect = this.countrySelect = ""
            for (let h = 0; h < this.ADD_EMRA_HEADERS.length; h++) {
                const el = document.getElementById(this.ADD_EMRA_HEADERS[h] + "Select") as HTMLInputElement
                el.value = ""
                el.dispatchEvent(new Event("input"))
            }
        } else {
            for (let h = 0; h < selected.headers.length; h++) {
                const item = selected.items[h]
                switch (selected.headers[h]) {
                    case "Board":   this.boardSelect   = item.id; break
                    case "Storage": this.storageSelect = item.id; break
                    case "Release": this.releaseSelect = item.id; break
                    case "Country": this.countrySelect = item.id; break
                }
                const el = document.getElementById(selected.headers[h] + "Select") as HTMLInputElement
                el.value = item.id
                el.dispatchEvent(new Event("input"))
            }
        }
    }

    validate(): boolean {
        return this.currentForm.validate()
    }

    validateAndSaveData(): boolean {
        if (!this.currentForm?.validate()) return false
        this.$store.commit("addEmra/SET_PRELOADED", false)

        let modelName = ""
        let stateName = ""
        let selectName = ""
        let data = { }
        let newNodeInfo: NodeInfo|null = null

        switch (this.extraFormIsShown) {
            case "new-board-form":
                modelName = "Board"; stateName = "boards"; selectName = "boardSelect"
                data = {
                    Name: this.newBoardName,
                    Manufacturer: this.newBoardManufacturer,
                    Version: this.newBoardVersion,
                    Bit: this.newBoardBit,
                    Documentation: this.newBoardDocumentation
                }
                break
            case "new-storage-form":
                modelName = "Storage"; stateName = "storages"; selectName = "storageSelect"
                data = {
                    Name: this.newStorageName,
                    Type: this.newStorageType,
                    Manufacturer: this.newStorageManufacturer,
                    Size: this.newStorageSize
                }
                break
            case "new-release-form":
                modelName = "Release"; stateName = "releases"; selectName = "releaseSelect"
                data = {
                    Version: this.newReleaseVersion,
                    SupportedOperatingSystemId: this.newReleaseSupportedOSSelect,
                    ReleasedAt: this.newReleaseReleasedAt
                }
                break
            case "new-country-form":
                modelName = "Country"; stateName = "countries"; selectName = "countrySelect"
                data = {
                    Code: this.newCountryCode,
                    Name: this.newCountryName
                }
                break
            case "new-sim-form":
                modelName = "Sim"; stateName = "sims"; selectName = "simSelect"
                data = {
                    Number: this.newSimNumber,
                    CardNumber: this.newSimCardNumber,
                    PUKCode: this.newSimPukCode,
                    UserName: this.newSimUsername,
                    Password: this.newSimPassword,
                    PrivateIPAddress: this.newSimPrivateIP,
                    NetworkId: this.newSimNetworkSelect,
                    CarrierBoardId: this.newSimCarrierBoardSelect
                }
                break
            case "new-system-type-form":
                modelName = "SystemTypeImage"; stateName = "systemTypeImages"
                data = {
                    SystemType: this.newSystemTypeId,
                    Image: this.newSystemTypeImageValue ? Buffer.from(this.newSystemTypeImageValue, "ascii").toString("base64") : null
                }
                break
            default:
                modelName = "Node"
                data = {
                    Alias: this.chosenEmraName,
                    BoardId: this.boardSelect,
                    StorageId: this.storageSelect,
                    ReleaseId: this.releaseSelect,
                    SIMId: this.simSelect !== "" ? this.simSelect : null,
                    CountryId: this.countrySelect,
                    EmraSerialId: this.chosenEmraSerialId,
                    PlacementDate: this.placementDate,
                    ReadyForPlacement: false,
                    Prepared: false,
                    ToBePrepared: true
                }
                newNodeInfo = {
                    id: "",
                    alias: this.chosenEmraName,
                    emraSerialId: this.chosenEmraSerialId,
                    updatedAt: null,
                    placementDate: this.placementDate,
                    nodeStatus: null,
                    outOfUse: false,
                    readyForPlacement: false,
                    prepared: false,
                    toBePrepared: true
                }
        }

        // Add inputted information to database
        let success = false

        if (modelName != "") {
            try {
                this.$store.dispatch("adminCrud/Create", { Model: modelName, Entity: data })
                    .then((result: any) => {
                        this.$data["addedObjId"] = result.entity?.Id
                        if (!result.err) {
                            if (stateName != "") this.$store.commit("addEmra/ADD_DATA", { Model: stateName, Entity: result.entity })
                            if (modelName == "Node" && newNodeInfo) {
                                newNodeInfo.id = this.$data["addedObjId"]
                                this.$store.commit("addEmra/ADD_DATA", { Model: "emraNames", Entity: this.chosenEmraName })
                                this.$store.commit("default/ADD_NODE", newNodeInfo)
                            }
                            if (selectName != "") {
                                this.$data[selectName] = result.entity.Id
                                const el = document.getElementById(modelName + "Select") as HTMLInputElement
                                el.value = result.entity.Id
                                el.dispatchEvent(new Event("input"))
                                this.$store.commit("addEmra/ADD_DATA", { Model: modelName, Entity: result.entity })
                            }
                            this.snack("Data succesvol opgeslagen", SnackbarStatus.SUCCESS)
                            success            = true
                        }
                        else {
                            this.snack("Fout: data niet opgeslagen", SnackbarStatus.FAIL)
                            success            = false
                        }
                    })
                    .finally(() => {
                        if (!this.extraFormIsShown) {
                            // Add EMRA's system type to database if inputted
                            if (this.selectedSystemType && this.systemTypeNodeVariableId && this.addedObjId) {
                                try {
                                    this.$store.dispatch("adminCrud/Create", { Model: "NodeVariableInput", Entity: {
                                        NodeId: this.addedObjId,
                                        NodeVariableId: this.systemTypeNodeVariableId,
                                        Value: this.selectedSystemType
                                    } })
                                        .then((result: any) => {
                                            if (!result.err) {
                                                this.$store.commit("addEmra/ADD_DATA", { Model: "NodeVariableInput", Entity: result.entity })
                                            }
                                            else {
                                                this.snack("Fout: systeemtype niet opgeslagen", SnackbarStatus.FAIL)
                                            }
                                        })
                                } catch (_) { "" }
                            }

                            // Add relation between user and EMRA
                            if (this.userId && !this.extraFormIsShown && this.addedObjId) {
                                try {
                                    this.$store.dispatch("adminCrud/Create", { Model: "NodeUserRelation", Entity: {
                                        UserId: this.userId,
                                        NodeId: this.addedObjId,
                                        Permission: 0
                                    } })
                                    .then((result: any) => {
                                        if (!result.err) {
                                            this.$store.commit("addEmra/ADD_DATA", { Model: "NodeUserRelation", Entity: result.entity })
                                        }
                                        else {
                                            this.snack("Fout: EMRA-box kon niet gekoppeld worden aan gebruiker", SnackbarStatus.FAIL)
                                        }
                                    })
                                    .finally(() => {
                                        this.$store.commit('detailNode/SET_NODE_INFO', { ...newNodeInfo })
                                        this.$store.commit('meters/SET_NODE_INFO', { ...newNodeInfo })
                                        this.$store.commit('modNode/SET_NODE_INFO', { ...newNodeInfo })
                                        this.$store.commit('emraPageInfo/SET_NODE_INFO', { ...newNodeInfo } )
                                        this.$router.push({ name: 'ModEMRAPage'})
                                    })
                                } catch (_) { "" }
                            }
                        }

                        this.hidePopupForms()
                    })
            } catch (_) { "" }
        }

        this.$store.commit("addEmra/SET_PRELOADED", true)
        return success
    }

    showConfigForm(number: number) {
        this.showAddConfigsForm = true
        this.addConfigsCurrentForm = number
    }

    hidePopupForms(): void {
        this.showAddConfigsForm = false;    this.addConfigsCurrentForm = 0
        this.showSimForm        = false
        this.showSystemTypeForm = false
    }

    vClickOutsideIncluded(): HTMLElement[] {
        const classes = ["includeClickOutside"]
        const result: HTMLElement[] = []

        classes.forEach(name => {
            const elements = document.getElementsByClassName(name);
            (Array.from(elements) as HTMLElement[]).forEach(el => {
                result.push(el)
            })
        })

        return result
    }

    uppercaseInput(e: string) {
        const el = document.getElementById("country-code-field") as HTMLInputElement
        const ss = el.selectionStart
        const se = el.selectionEnd
        this.newCountryCode = e.toLocaleUpperCase()
        el.value = e.toLocaleUpperCase()
        el.selectionStart = ss
        el.selectionEnd = se
    }

    snack(text: string, status: SnackbarStatus): void {
        this.snackbarColor = status === SnackbarStatus.FAIL ? this.SNACKBAR_FAIL_COLOR : this.SNACKBAR_SUCCESS_COLOR
        this.snackbarText  = text
        this.snackbar      = true
    }

    objFromId(id: string, objects: any[]|null, attr = "id"): any {
        return objects?.find(o => o[attr] == id) || ""
    }

    formatDate(date: string): string {
        return date.substring(8, 10) + "/" + date.substring(5, 7) + "/" + date.substring(0, 4)
    }


    /* override methods */
    mounted(): void {
        this.$nextTick(() => {
            this.formKey = (this.formKey + 1) % 10
            this.setEMRAConfigSelections()
        })
    }

    created(): void {
        window.addEventListener("keydown", (k) => {
            if (this.extraFormIsShown && k.key == "Escape") {
                this.hidePopupForms()
            }
        })

        this.$store.dispatch('addEmra/init')
    }

}
