




























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    components: {}
})
export default class ConfigurationCard extends Vue {
    @Prop()
    title?: string

    @Prop()
    headers!: string[]

    @Prop()
    items!: any[]

    @Prop()
    selected?: boolean

    get backgroundColor(): string {
        return this.selected ? "#d4e6a5" : "#f5f5f5"
    }
}
